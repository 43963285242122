<template>
  <div class="subrouter_container">
    <div class="content">
      <div class="left">
        <div v-for="item in menuList" :key="item.name">
          <div class="title">{{ item.title }}</div>
          <div class="item" :class="path === sub.path ? 'active' : ''" v-for="sub in item.children" :key="sub.title" @click="routerCilck(sub.path)">
            {{ sub.title }}
          </div>
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "",
      menuList: [
        {
          title: "订单中心",
          children: [
            {
              title: "所有订单",
              path: "/index/subRouter/order",
            },
            {
              title: "退货申请",
              path: "/index/subRouter/returnGoods",
            },
          ],
        },
        {
          title: "账户中心",
          children: [
            {
              title: "账户首页",
              path: "/index/subRouter/homepage",
            },
            {
              title: "基本资料",
              path: "/index/subRouter/baseInfo",
            },
            {
              title: "电子资质",
              path: "/index/subRouter/eleFile",
            },
            {
              title: "多店管理",
              path: "/index/subRouter/multiStore",
            },
            {
              title: "绑定微信",
              path: "/index/subRouter/bindwx",
            },
            {
              title: "收货地址",
              path: "/index/subRouter/address",
            },
            {
              title: "积分抽奖",
              path: "/index/subRouter/choujiang",
            },
          ],
        },
        {
          title: "我的钱包",
          children: [
            {
              title: "资产管理",
              path: "/index/subRouter/balance",
            },
            {
              title: "积分管理",
              path: "/index/subRouter/integral",
            },
            {
              title: "积分兑换记录",
              path: "/index/subRouter/exchange",
            },
            {
              title: "优惠券",
              path: "/index/subRouter/coupon",
            },
          ],
        },
        {
          title: "自助服务",
          children: [
            {
              title: "快捷采购",
              path: "/index/subRouter/quick",
            },
            {
              title: "采购计划",
              path: "/index/subRouter/purchase",
            },
            {
              title: "电子发票",
              path: "/index/subRouter/einvoice",
            },
            {
              title: "我的收藏",
              path: "/index/subRouter/collect",
            },
            {
              title: "意见反馈",
              path: "/index/subRouter/opinion",
            },
          ],
        },
      ],
    };
  },
  created() {
    if (this.$route.path === "/index/subRouter/orderDetail") {
      this.path = "/index/subRouter/order";
    } else {
      this.path = this.$route.path;
    }
  },
  watch: {
    $route(to) {
      if (to.path === "/index/subRouter/orderDetail") {
        this.path = "/index/subRouter/order";
      } else {
        this.path = to.path;
      }
    },
  },
  methods: {
    routerCilck(path) {
      if (path === "/index/subRouter/order" && this.$route.path === "/index/subRouter/orderDetail") {
        this.$router.push("/index/subRouter/order");
        return;
      }
      if (this.path === path) return;
      this.$router.push(path);
      this.path = path;
    },
  },
};
</script>

<style lang="less" scoped>
.subrouter_container {
  padding-top: 10px;
  .content {
    display: flex;
    .left {
      border-radius: 4px;
      width: 200px;
      background-color: #fff;
      margin-right: 10px;
      .title {
        height: 34px;
        background: @themeColor;
        color: #fff;
        border-radius: 4px 4px 0px 0px;
        font-size: 16px;
        font-weight: bold;
        line-height: 34px;
        padding-left: 47px;
      }
      .item {
        margin: 10px 0 20px 47px;
        color: #666666;
        cursor: pointer;
        &:last-child {
          margin-bottom: 10px;
        }
        &:hover {
          color: @themeColor;
        }
        &.active {
          color: @themeColor;
        }
      }
    }
    .right {
      border-radius: 4px;
      flex: 1;
      background-color: #fff;
      padding: 20px;
    }
  }
}
</style>
